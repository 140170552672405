<template>
    <div>
      <!-- 此组件用于在派单时，查看审批记录 -->
      <Tabs>
          <TabPane label="任务详情">
              <component :is="componentName" :id="relateId" :task="task"/>
          </TabPane>
          <TabPane label="任务进度">
              <history ref="ApprovalHistory" :relate-id="relateId" :type="'cancel'" />
          </TabPane>
      </Tabs>

    </div>
</template>

<script>
import History from '@/components/approval/History'
import Brand from './components/Brand'
import Contract from './components/Contract'
import Statement from './components/Statement'
import StatementChange from './components/StatementChange'
import ContractChange from './components/ContractChange'
import OrderScreen from './components/OrderScreen'
import UninstallScreen from './components/UninstallScreen'
import Agreement from './components/Agreement'
import Special from './components/Special'
import Fine from './components/Fine'
import Maintenance from './components/Maintenance'
import StatementAdjustment from './components/StatementAdjustment'
import { getTask } from '@/api/approval/approvaltask'

export default {
  components: {
    History,
    Brand,
    Statement,
    StatementChange,
    ContractChange,
    OrderScreen,
    UninstallScreen,
    Agreement,
    Special,
    Fine,
    Contract,
    Maintenance,
    StatementAdjustment
  },
  data () {
    return {
      approvalType: '',
      relateId: '',
      task: {
        status
      },
      componentName: ''
    }
  },
  methods: {
    initPageData (detailId) {
      getTask({ taskId: detailId }).then(res => {
        this.task = res
        this.approvalType = this.task.processtype
        this.relateId = this.task.relateId
        this.$refs.ApprovalHistory.getProcessCancelDetail(res)

        switch (this.approvalType) {
          case 3: // 合同
            this.componentName = 'Contract'
            break
          case 4:
            this.componentName = 'Customer'
            break
          case 6:
            this.componentName = 'Brand'
            break
          case 7:
            this.componentName = 'Statement'
            break
          case 8:
            this.componentName = 'ContractChange'
            break
          case 9:
            this.componentName = 'Contract'
            break
          case 10:
            this.componentName = 'OrderScreen'
            break
          case 11:
            this.componentName = 'StatementChange'
            break
          case 12:
            this.componentName = 'Agreement'
            break
          case 13:
            this.componentName = 'Special'
            break
          case 14:
            this.componentName = 'Fine'
            break
          case 15:
            this.componentName = 'Maintenance'
            break
          case 16:
            this.componentName = 'UninstallScreen'
            break
          case 17:
            this.componentName = 'Maintenance'
            break
          case 19:
            this.componentName = 'StatementAdjustment'
            break
          case 31:
            this.componentName = 'LocalLifeScreen'
            break
          default:
            break
        }
      })
    }
  }
}
</script>
