import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 获取订单服务项使用情况
 * @param {Object} data
 * @returns
 */
export function getOrderServiceItemList (data) {
  return request.post('/ooh-msp/v1/orderserviceitem/getOrderServiceItemList', qs.stringify(data))
}
