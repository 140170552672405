<style lang="less">
.asset-tag {
  a {
    display: inline-flex;
    color: #fff;
    border: 1px solid #d7d7d7;
    line-height: 24px;
    padding: 0 6px;
    border-radius: 2px;
  }
  a:hover {
    color: #f4f4f4;
    border-color: #d8d8d8;
  }
  .tag-actived {
    background: #2d8cf0;
    border-color: #2b85e4;
  }
}
</style>
<template>
  <div>
    <div class="m-b-10">
      <Divider
        dashed
        style="color:#fff"
      >分布统计</Divider>
      <div class="p-b-10">
        <span>任务类型：</span>
        <Tag color="primary">{{curTasktypeName}}</Tag>
      </div>
      <service-fee-item ref="serviceFeeItem" :orderId="taskOrderId" class="p-b-10" />
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">任务状态</h4>
        <div>
          <Table
            stripe
            size="small"
            :data="statusStatisticArray"
            :columns="statusColumns"
          ></Table>
        </div>
      </div>
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">供应商分布</h4>
        <div>
          <Table
            stripe
            size="small"
            :data="companyStatisticArray"
            :columns="companyColumns"
          ></Table>
        </div>
      </div>
      <div
        class="p-b-10"
        v-if="curTaskType!==11"
      >
        <h4 class="workplatform-title m-t-10 m-b-20">站点数量分布</h4>
        <div class="m-b-10 asset-tag">
          <a
            href="javascript:void(0)"
            :class="assetIds.indexOf(item.assetId)!==-1?'tag-actived m-r-5 m-b-5':'m-r-5 m-b-5'"
            v-for="item in assetArray"
            :key="'assetId_'+item.assetId"
            @click="changeAsset(item.assetId)"
          >{{item.assetName}}</a>
        </div>
        <div>
          <Table
            stripe
            size="small"
            :data="showDomianArray"
            :columns="domainColumns"
          ></Table>
        </div>
      </div>
      <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">审批清单</h4>
        <div>
          <Table
            stripe
            size="small"
            :data="taskApproveList"
            :columns="approveListColumns"
          ></Table>
        </div>
      </div>
      <!-- <div class="p-b-10">
        <h4 class="workplatform-title m-t-10 m-b-20">任务费用汇总</h4>
        <Row
          :gutter="8"
          v-if="[8,9,11].includes(curTaskType)"
        >
          <i-col span="12">总计</i-col>
          <i-col
            span="12"
            class="text-right"
            style="color:#44bd32;font-weight:bold"
          >{{totalFee}}</i-col>
        </Row>
        <div v-else>
          <Table
            stripe
            size="small"
            :data="feeStatisticArray"
            :columns="feeColumns"
          ></Table>
        </div>
      </div> -->
    </div>

    <Modal
      v-model="modalApproveDetail"
      :title="modalTitle"
      width="1200"
      footer-hide
    >
      <ApprovalDetail ref="ApprovalDetail" />
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { sysMixins } from '@/assets/mixins/sys'
import { toMoney } from '@/utils/wnumb_own'
import ApprovalDetail from '@/components/approvalTask/ApprovalDetailComponent'
import { getApprovalSchedule } from '@/api/approval/approvaltask'
import ServiceFeeItem from '../../installSetting/common/ServiceFeeItem'

export default {
  mixins: [sysMixins],
  components: { ApprovalDetail, ServiceFeeItem },
  data () {
    return {
      assetArray: [],
      assetIds: [],
      statusColumns: [
        { title: '任务状态', key: 'name' },
        { title: '数量', align: 'right', key: 'quantity' }
      ],
      companyColumns: [
        { title: '供应商', key: 'name', ellipsis: true, tooltip: true },
        { title: '数量', align: 'right', key: 'quantity' }
      ],
      domainColumns: [
        { title: '站点', key: 'domainName' },
        { title: '数量', align: 'right', key: 'quantity' }
      ],
      feeColumns: [
        { title: '资源类型', key: 'name' },
        {
          title: '金额',
          align: 'right',
          key: 'totalPrice',
          render: (h, params) => {
            return h('span', {
              style: { color: '#44bd32', fontWeight: 'bold' }
            }, toMoney(params.row.totalPrice))
          }
        }
      ],
      showDomianArray: [],
      approveListColumns: [
        {
          title: '创建时间',
          render: (h, params) => {
            return h('a',
              {
                on: {
                  click: () => {
                    this.handleShowApproveDetail(params.row)
                  }
                }
              }, params.row.createTime)
          }
        },
        { title: '状态', align: 'right', width: 100, key: 'statusName' }
      ],
      modalApproveDetail: false,
      modalTitle: '',
      curTasktypeName: ''
    }
  },
  created () {
    this.init()
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.serviceFeeItem.getServiceFeeData()
    })
  },
  computed: {
    statusStatisticArray () {
      return this.$store.state.workOrder.statusStatisticArray
    },
    companyStatisticArray () {
      return this.$store.state.workOrder.companyStatisticArray
    },
    domainStatisticArray () {
      return this.$store.state.workOrder.domainStatisticArray
    },
    // feeStatisticArray () {
    //   return this.$store.state.workOrder.feeStatisticArray
    // },
    totalFee () {
      return toMoney(this.$store.state.workOrder.totalFeeStatistic)
    },
    curTaskType () {
      return this.$store.state.workOrder.taskType
    },
    taskApproveList () {
      return this.$store.state.workOrder.taskApproveList
    },
    taskTypeArray () {
      return this.$store.state.workOrder.taskTypeArray
    },
    taskOrderId () {
      return this.$store.state.workOrder.taskOrderId
    }
  },
  methods: {
    ...mapActions(['getStatusStatisticData', 'getCompanyStatisticData', 'getDomainStatisticData', 'getTaskItemData', 'getTaskApproveListData', 'getFeeStatisticData', 'getTotalFeeStatisticData']),
    init () {
      this.getStatusStatisticData()
      this.getCompanyStatisticData()
      // if ([8, 9, 11].includes(this.curTaskType)) { // 巡检、维护、临时任务只获取总费用
      //   this.getTotalFeeStatisticData()
      // } else {
      //   this.getFeeStatisticData()
      // }

      if (this.curTaskType !== 11) {
        // 非临时任务请求
        this.getDomainStatisticData()
      }
      this.getTaskApproveListData()
      this.curTasktypeName = this.taskTypeArray.find(x => x.value === this.curTaskType).name
    },
    changeAsset (id) {
      if (!this.assetIds.some(x => x === id)) {
        this.assetIds.push(id)
      } else {
        this.assetIds.splice(this.assetIds.indexOf(id), 1)
      }
      this.$store.commit('set_selected_task_item_ids', [])
      this.getTaskItemData({ assetIds: JSON.stringify(this.assetIds) })
      this.showDomianArray = this.assetIds.length === 0 ? this.domainStatisticArray : this.domainStatisticArray.filter(x => this.assetIds.indexOf(x.assetId) !== -1)
    },
    handleShowApproveDetail (param) {
      this.modalTitle = param.createTime + ' 审批详情'
      this.modalApproveDetail = true
      const postData = {
        processtype: param.processtype,
        relateId: param.id,
        includeHistoryProcessinstance: true
      }

      getApprovalSchedule(postData).then(res => {
        const approvlTaskId = res.pop().id
        this.$refs.ApprovalDetail.initPageData(approvlTaskId)
      })
    }
  },
  watch: {
    domainStatisticArray: {
      deep: true,
      immediate: true,
      handler (val) {
        this.assetArray = []
        this.assetIds = []
        if (val && val.length) {
          this.showDomianArray = val
          this.showDomianArray.forEach(item => {
            if (!this.assetArray.some(x => x.assetId === item.assetId)) {
              this.assetArray.push({
                assetId: item.assetId,
                assetName: item.assetName
              })
              this.assetIds.push(item.assetId)
            }
          })
        }
      }
      // curTaskType: {
      //   deep: true,
      //   immediate: true,
      //   handler (val) {
      //     if (val) {
      //       this.curTasktypeName = this.taskTypeArray.find(x => x.value === val).name
      //     }
      //   }
      // }
    }
  }
}
</script>
